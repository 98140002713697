import * as React from "react"
import {graphql, Link} from "gatsby";
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";
import Typography from "@mui/material/Typography";
import {Box, Button, Card, CardContent, Divider, Grid, Alert} from "@mui/material";
import {ArrowForward} from "@mui/icons-material";
import Container from "@mui/material/Container";

import {Slogan} from "../components/typography";
import {sectionPadding} from "../theme";
import {Bouwtype} from "../graphqlTypes";
import Oppervlakte from "../components/oppervlakte";
import Prijs from "../components/prijs";

interface BouwtypeHomeProps {
    bouwtypes: Bouwtype[]
    titel: string
    text: string
    url: string
}
const BouwtypeHome = ({bouwtypes, titel, text, url}: BouwtypeHomeProps) => {
    const prijsVanaf = Math.min(...bouwtypes.map((bouwtype: Bouwtype) => bouwtype.algemeen?.koopaanneemsomVanaf || 0))
    const woonOppervlakTot = Math.max(...bouwtypes.map((bouwtype: Bouwtype) => bouwtype.algemeen?.woonoppervlakteTot || 0))
    const woonOppervlakVanaf = Math.min(...bouwtypes.map((bouwtype: Bouwtype) => bouwtype.algemeen?.woonoppervlakteVanaf || 0))
    const image = bouwtypes[0].hoofdfoto?.localFile.childImageSharp.gatsbyImageData
    return (
        <Box
            marginBottom={5}
        >
            <Box
                marginBottom={5}
            >
                {image &&
                    <GatsbyImage
                        image={image}
                        alt={titel}
                    />
                }
            </Box>
            <Box
                maxWidth={294}
            >
                <Typography
                    variant={'h3'}
                    sx={{
                        marginBottom: 3
                    }}
                >
                    {titel}
                </Typography>
                <Typography>
                    {text}
                </Typography>
                <Divider
                    sx={{
                        marginY: 3
                    }}
                />
                <Typography
                    color={'primary.main'}
                    fontFamily={'Rajdhani'}
                    fontWeight={'bold'}
                    sx={{
                        marginY: 3
                    }}
                >
                    <Oppervlakte oppervlakte={`${woonOppervlakVanaf} - ${woonOppervlakTot}`}/> <Box component={'span'} color={'secondary.main'}>~</Box> V.A. <Prijs prijs={prijsVanaf} /> V.O.N.
                </Typography>
                <Button
                    component={Link}
                    to={url}
                    variant={'contained'}
                >
                    Bekijk woning
                </Button>
            </Box>
        </Box>
    )
}

interface Props {
    data: PageQueryData
}
export default ({data}: Props) => {
    const sectionOverlap = 20;
    return (
        <article>
            <Box
                textAlign={'center'}
                position={'relative'}
            >
                <StaticImage
                    alt={'header'}
                    src={'../images/impressies/Blok-2-en-3.jpg'}
                    aspectRatio={1370/700}
                />
            </Box>

            <Box
                sx={{
                    backgroundColor: 'secondary.light',
                }}
            >
                <Container>
                    <Box
                        sx={{
                            maxWidth: 489,
                            paddingTop: sectionPadding
                        }}
                    >
                        <Box
                            marginBottom={2}
                        >
                            <Slogan>
                                Daverhof Kerk-Avezaath
                            </Slogan>
                        </Box>
                        <Typography
                            variant={'h2'}
                        >
                            Uiterst aangenaam wonen in Daverhof
                        </Typography>
                        <Button
                            component={Link}
                            to={'/woningen'}
                            variant={'contained'}
                        >
                            Bekijk de woningen
                        </Button>
                    </Box>
                </Container>
                <Container
                    sx={{
                        paddingTop: sectionPadding,
                        paddingBottom: sectionPadding*2+sectionOverlap,
                        backgroundColor: 'secondary.light'
                    }}
                >
                    <Grid
                        container
                        alignItems={'center'}
                        spacing={3}
                    >
                        <Grid
                            item
                            md={4}
                            sx={{
                                zIndex: 2
                            }}
                        >
                            <Card
                                elevation={0}
                                sx={{
                                    backgroundColor: 'secondary.light',
                                    marginRight: {md: -16},
                                    marginLeft: {md: 8}
                                }}
                            >
                                <CardContent>
                                    <Typography variant={'h3'}>
                                        Wonen in de Betuwe
                                    </Typography>
                                    <Typography>
                                        Kerk-Avezaath ligt in natuurgebied de Betuwe, dat deel uitmaakt van de streek Rivierenland. Hier staat het landleven centraal. Met de Betuwe als achtertuin is er zoveel te doen. Van wandelen tussen de boomgaarden vol prachtige witte en roze bloesem in het voorjaar, vertier zoeken in en langs het water tot het plukken van het lekkerste fruit in de nazomer. De term ‘home-made’ is hier pas echt van toepassing.
                                    </Typography>

                                    <Button
                                        endIcon={<ArrowForward />}
                                    >
                                        Lees meer
                                    </Button>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={8}
                            sx={{
                                paddingRight: {md: 16}
                            }}
                        >
                            <StaticImage
                                src={'../images/sfeer/GettyImages-1059234782.jpg'}
                                alt={'betuwe'}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Container
                sx={{
                    marginTop: -sectionOverlap,
                    paddingBottom: sectionPadding
                }}
            >
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                        <BouwtypeHome
                            bouwtypes={data.eenEnVijf.nodes}
                            titel={'Blok 1 & 5'}
                            url={'/woningen/blok1-en-5'}
                            text={'Blok 1 omvat royale drie-onder-één-kapwoningen. Blok 5 siert het woonplan met 5 rijwoningen. De in totaal 8 woningen variëren van 127 tot 131m2 woonoppervlak. Elke woning staat op een ruim perceel waarbij de tuinen van Blok 1 op het oosten zijn gericht en die van Blok 5 >>>'}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                        <BouwtypeHome
                            bouwtypes={data.tweeEnDrie.nodes}
                            titel={'Blok 2 & 3'}
                            url={'/woningen/blok2-en-3'}
                            text={'Blok 2 en 3 zijn beide twee-onder-één-kapwoningen en zijn levensloopbestendig. Het woonoppervlak varieert van 104 tot en met 118 m2. De bouwnummers 4 en 7 beschikken over een topgevel. Alle woningen beschikken over een eigen parkeerplaats. >>>'}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                        <BouwtypeHome
                            bouwtypes={data.vierEnZes.nodes}
                            titel={'Blok 4 & 6 '}
                            url={'/woningen/blok4-en-6'}
                            text={'Blok 4 beslaat een fraai en karaktervol rijtje drie-onder-één-kapwoningen. In Blok 6 sieren straks 5 ruime rijwoningen. De in totaal 8 woningen variëren van 94 tot 108m2 woonoppervlak. Elke woning beschikt over een dakkapel aan de voorkant voor extra ruimte op de >>>'}
                        />
                    </Grid>
                </Grid>
            </Container>
        </article>
    )
}

interface PageQueryData {
    eenEnVijf: {
        nodes: Bouwtype[]
    }
    tweeEnDrie: {
        nodes: Bouwtype[]
    }
    vierEnZes: {
        nodes: Bouwtype[]
    }
}

export const query = graphql`
    fragment BouwtypeHome on Bouwtype {
        algemeen {
            woonoppervlakteTot
            woonoppervlakteVanaf
            koopaanneemsomVanaf
        }
        hoofdfoto {
            localFile {
                childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH,
                        aspectRatio: 1.3
                    )
                }
            }
        }
    }
    query {
        eenEnVijf: allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 1", "Blok 5"]}}}) {
            nodes {
                ...BouwtypeHome
            }
        }
        tweeEnDrie: allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 2", "Blok 3"]}}}) {
            nodes {
                ...BouwtypeHome
            }
        }
        vierEnZes: allBouwtype(filter: {algemeen: {omschrijving: {in: ["Blok 4", "Blok 6"]}}}) {
            nodes {
                ...BouwtypeHome
            }
        }
    }
`
